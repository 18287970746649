import React from 'react';
import { func, string } from 'prop-types';
import { Rows, Columns } from '@blendle/lego';
import { translate } from 'instances/i18n';
import Link from 'components/Link';
import CSS from './style.scss';

const TITLE_COPY = new Map([
  ['generic', 'install_app_banner.generic.title'],
  ['audio', 'install_app_banner.audio.title'],
]);

const BODY_COPY = new Map([
  ['generic', 'install_app_banner.generic.body'],
  ['audio', 'install_app_banner.audio.body'],
]);

const BUTTON_COPY = new Map([
  ['generic', 'install_app_banner.generic.button'],
  ['audio', 'install_app_banner.generic.button'],
]);

function InstallAppBanner({ onDismiss, onClickLink, appUrl, appFeature }) {
  return (
    <Rows className={CSS.banner}>
      <Columns className={CSS.topRow}>
        <strong className={CSS.title}>{translate(TITLE_COPY.get(appFeature))}</strong>
        <div className={CSS.buttonDismiss} onClick={onDismiss} />
      </Columns>
      <p className={CSS.body}>{translate(BODY_COPY.get(appFeature))}</p>
      <Link className={CSS.installLink} onClick={onClickLink} href={appUrl}>
        {translate(BUTTON_COPY.get(appFeature))}
      </Link>
    </Rows>
  );
}

InstallAppBanner.propTypes = {
  onDismiss: func.isRequired,
  onClickLink: func.isRequired,
  appUrl: string.isRequired,
  appFeature: string,
};

InstallAppBanner.defaultProps = {
  appFeature: 'generic',
};

export default InstallAppBanner;
