import alt from 'instances/altInstance';
import SectionsPageStore from './SectionsPageStore';
import PersonalPageActions from '../actions/PersonalPageActions';

class PersonalPageStore extends SectionsPageStore {
  constructor() {
    super();

    this.bindActions(PersonalPageActions);
  }
}

export default alt.createStore(PersonalPageStore, 'PersonalPageStore');
