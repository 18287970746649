import React from 'react';
import { Button } from '@blendle/lego';
import classNames from 'classnames';
import CSS from './style.scss';

function PersonalPageFooter() {
  return (
    <footer className={CSS.personalPageFooter}>
      <div className={CSS.content}>
        <h2 className={CSS.title}>Dat was &apos;m voor vandaag!</h2>
        <p className={CSS.body}>
          Je bent weer helemaal up-to-date. Nog meer lezen? Dat kan, we verzamelen hier de artikelen
          die eerder deze week voor je geselecteerd zijn.
        </p>
        <Button className={classNames(CSS.button)} color="razzmatazz" component="a" href="/earlier">
          Eerder deze week
        </Button>
      </div>
    </footer>
  );
}

export default PersonalPageFooter;
