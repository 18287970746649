import React, { Component } from 'react';
import altConnect from 'higher-order-components/altConnect';
import withRouter from 'react-router/lib/withRouter';
import { compose } from '@blendle/recompose';
import redirectToStoredRoute from 'higher-order-components/redirectToStoredRoute';
import AuthStore from 'stores/AuthStore';
import ModuleNavigationStore from 'stores/ModuleNavigationStore';
import PersonalPage from '../components/PersonalPage';
import { getTilesUrl, isLoadingSectionFeed, shouldFetchTiles } from '../selectors/sections';
import PersonalPageStore from '../stores/PersonalPageStore';
import PersonalPageActions from '../actions/PersonalPageActions';

function preloadSection(sectionId) {
  const { sections, sectionFeeds } = PersonalPageStore.getState();
  const section = sections.get(sectionId);

  if (shouldFetchTiles(section, sectionFeeds)) {
    PersonalPageActions.fetchSectionFeed(section.id, getTilesUrl(section));
  }
}

class PersonalPageContainer extends Component {
  componentDidMount() {
    const userId = AuthStore.getState().user.id;
    PersonalPageActions.fetchPersonalPage.defer(userId);
  }

  componentWillUnmount() {
    PersonalPageActions.resetScrollPosition.defer();
  }

  render() {
    return <PersonalPage preloadSection={preloadSection} {...this.props} />;
  }
}

const mapStateToProps = ({ personalPageState, moduleNavigationState }, { route }) => {
  const {
    status,
    activeFeedbackItemId,
    sectionIds,
    sectionFeeds,
    scrollPosition,
  } = personalPageState;
  const { activeModule } = moduleNavigationState;

  return {
    status,
    isActive: activeModule === route.module,
    isLoadingSectionFeed: isLoadingSectionFeed(sectionIds, sectionFeeds),
    activeFeedbackItemId,
    sectionIds,
    scrollPosition,
  };
};
mapStateToProps.stores = { PersonalPageStore, ModuleNavigationStore };

const enhance = compose(redirectToStoredRoute, withRouter, altConnect(mapStateToProps));

export default enhance(PersonalPageContainer);
