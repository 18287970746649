import React, { Component } from 'react';
import Cookies from 'cookies-js';
import { ADJUST_CAMPAIGN, ADJUST_ADGROUP, ADJUST_CREATIVE, INTERNAL_LOCATION } from 'app-constants';
import Analytics from 'instances/analytics';
import AuthStore from 'stores/AuthStore';
import { getAdjustUrl, getFallBackUrl, getAdjustLoginDeeplink } from 'helpers/adjust';
import { equals, ifElse } from 'ramda';
import BrowserEnv from 'instances/browser_environment';
import InstallAppBanner from '../components/InstallAppBanner';

export const COOKIE_NAMES = new Map([
  ['generic', 'DID_DISMISS_INSTALL_APP_BANNER-STORIES_LOCATION'],
  ['audio', 'DID_DISMISS_INSTALL_APP_BANNER-AUDIO'],
]);

function setCookie(appFeature) {
  Cookies.set(COOKIE_NAMES.get(appFeature), true, { expires: Infinity });
}

function trackDismiss(appFeature) {
  Analytics.track('Banner Dismissed', {
    location_in_layout: appFeature === 'audio' ? 'audio-banner' : 'stories-deprecated-banner',
    internal_location: INTERNAL_LOCATION.TIMELINE_PREMIUM,
  });
}

function trackClickLink(appFeature) {
  Analytics.track('Download Native App Button Clicked', {
    location_in_layout: appFeature === 'audio' ? 'audio-banner' : 'stories-deprecated-banner',
    internal_location: INTERNAL_LOCATION.TIMELINE_PREMIUM,
  });
}

const isAudio = equals('audio');
const creative = ifElse(
  isAudio,
  () => ADJUST_CREATIVE.AUDIO,
  () => ADJUST_CREATIVE.GENERIC,
);

export default class InstallAppBannerContainer extends Component {
  constructor(props) {
    super(props);

    const { user } = AuthStore.getState();
    const userCountry = user.get('country');
    const appFeature = userCountry === 'NL' ? 'audio' : 'generic';
    const didDismissBanner = Cookies.get(COOKIE_NAMES.get(appFeature));
    const deeplink = getAdjustLoginDeeplink(user);

    this.state = {
      didDismissBanner,
      appFeature,
      deeplink,
    };
  }

  onDismiss = () => {
    const { appFeature } = this.state;

    setCookie(appFeature);
    trackDismiss(appFeature);

    this.setState({ didDismissBanner: true });
  };

  onClickLink = () => {
    const { appFeature } = this.state;

    trackClickLink(appFeature);
  };

  render() {
    const { didDismissBanner, appFeature, deeplink } = this.state;
    const isVisble = !didDismissBanner && BrowserEnv.platformHasApp();

    if (!isVisble) {
      return null;
    }

    const adjustUrl = getAdjustUrl({
      campaign: ADJUST_CAMPAIGN.PERSONAL_PAGE,
      adgroup: ADJUST_ADGROUP.BANNER,
      creative: creative(appFeature),
      fallback: getFallBackUrl(window.BrowserDetect.device),
      deeplink,
    });

    return (
      <InstallAppBanner
        {...this.props}
        onDismiss={this.onDismiss}
        onClickLink={this.onClickLink}
        appUrl={adjustUrl}
        appFeature={appFeature}
      />
    );
  }
}
