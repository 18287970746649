// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".personalPageFooter-3myo3{background:rgba(0,0,0,0.02);border-top:1.5px solid rgba(0,0,0,0.05);padding:20px 15px 30px}.content-1g1p_{max-width:460px;margin:0 auto}.title-3kOHW{font-family:\"GT-Walsheim\",sans-serif;font-size:24px;font-weight:600;color:#333;text-align:center}.body-2ZScL{margin-top:5px;font-family:\"GT-Walsheim\",sans-serif;font-size:16px;color:#948784;line-height:1.3;text-align:center}.button-2RrLc{width:100%;max-width:260px;margin:25px auto 0;text-align:center;display:block;font-size:16px}@media (min-width: 600px){.personalPageFooter-3myo3{padding:60px 20px}.title-3kOHW{font-size:32px}.body-2ZScL{font-size:18px}}@media (min-width: 1080px){.footer-TltFH{margin-top:150px}}\n", ""]);
// Exports
exports.locals = {
	"personalPageFooter": "personalPageFooter-3myo3",
	"content": "content-1g1p_",
	"title": "title-3kOHW",
	"body": "body-2ZScL",
	"button": "button-2RrLc",
	"footer": "footer-TltFH"
};
module.exports = exports;
