// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".banner-1QE-k{background:rgba(0,0,0,0.02);border-bottom:1px solid rgba(0,0,0,0.05);padding:15px}.topRow-ZIbHQ{justify-content:space-between}.title-3LTFD{color:#333;font-family:\"GT-Walsheim\",sans-serif;font-size:17px;margin-bottom:4px}.body-3FLCI{color:#948784;font-family:\"GT-Walsheim\",sans-serif;font-size:16px;line-height:1.2}.buttonDismiss-108mY:before{font-family:'blendle-icons';speak:none;font-style:normal;font-weight:normal;font-variant:normal;text-transform:none;text-indent:0;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;content:\"\\EA0D\";color:#948784}.installLink-1vfrq{margin-top:15px;background:linear-gradient(to bottom right, #ff1060, #ff6255);width:100%;height:44px;line-height:44px;color:white;font-family:\"GT-Walsheim\",sans-serif;font-size:16px;text-decoration:none;text-align:center;padding:0 10px;align-self:center;border-radius:4px}@media (min-width: 420px){.installLink-1vfrq{margin-top:20px;max-width:300px}}\n", ""]);
// Exports
exports.locals = {
	"banner": "banner-1QE-k",
	"topRow": "topRow-ZIbHQ",
	"title": "title-3LTFD",
	"body": "body-3FLCI",
	"buttonDismiss": "buttonDismiss-108mY",
	"installLink": "installLink-1vfrq"
};
module.exports = exports;
