import React from 'react';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import makeSectionContainer from '../factories/makePageSectionContainer';
import PersonalPageSectionActionsContainer from './PersonalPageSectionActionsContainer';
import PersonalPageStore from '../stores/PersonalPageStore';
import PersonalPageActions from '../actions/PersonalPageActions';

const actions = {
  fetchSectionFeed: PersonalPageActions.fetchSectionFeed.defer,
};

const Container = makeSectionContainer(PersonalPageStore, FeatureCapabilitiesStore, actions);

const PersonalPageContainer = props => (
  <Container {...props} sectionActionsContainer={PersonalPageSectionActionsContainer} />
);

export default PersonalPageContainer;
