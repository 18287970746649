import React, { useEffect } from 'react';
import { string, arrayOf, node, func, bool, number } from 'prop-types';
import { compose } from '@blendle/recompose';
import withViewportSize from 'higher-order-components/withViewportSize';
import classNames from 'classnames';
import { STATUS_ERROR, INTERNAL_LOCATION, STATUS_OK } from 'app-constants';
import resetStyles from 'higher-order-components/resetStyles';
import Analytics from 'instances/analytics';
import SegmentAnalytics from 'instances/segmentAnalytics';
import PersonalPageFooterContainer from '../../containers/PersonalPageFooterContainer';
import SectionsPageError from '../SectionsPageError';
import InstallAppBannerContainer from '../../containers/InstallAppBannerContainer';
import PersonalPageSectionContainer from '../../containers/PersonalPageSectionContainer';
import PageSectionsContainer from '../../containers/PageSectionsContainer';
import CSS from './style.scss';

const internalLocation = INTERNAL_LOCATION.TIMELINE_PREMIUM;

const PersonalPage = ({
  sectionIds,
  preloadSection,
  status,
  isActive,
  isLoadingSectionFeed,
  isMobileViewport,
  overlay,
  scrollPosition,
}) => {
  const scrollToPosition = () => {
    window.scrollTo(0, scrollPosition);
  };

  useEffect(() => {
    if (isActive) {
      const properties = { internal_location: internalLocation };

      Analytics.track('View Timeline', properties);
      SegmentAnalytics.page({ pageName: 'Timeline', properties });

      scrollToPosition();
    }
  }, [isActive]);

  // Make sure the footer is only shown when one ore more sections are visible
  const showFooter = sectionIds.length > 0 && status === STATUS_OK;

  return (
    <div
      className={classNames(CSS.personalPage, !isActive && CSS.hidden)}
      data-testid="personal-page"
    >
      {status === STATUS_ERROR && <SectionsPageError />}
      {isMobileViewport && <InstallAppBannerContainer />}
      <PageSectionsContainer
        sectionContainer={PersonalPageSectionContainer}
        sectionIds={sectionIds}
        preloadSection={preloadSection}
        status={status}
        isLoadingSectionFeed={isLoadingSectionFeed}
        internalLocation={internalLocation}
      />
      {showFooter && <PersonalPageFooterContainer />}
      {overlay}
    </div>
  );
};

const enhance = compose(resetStyles, withViewportSize({ debounce: 100 }));

PersonalPage.propTypes = {
  isActive: bool,
  isLoadingSectionFeed: bool.isRequired,
  isMobileViewport: bool.isRequired,
  preloadSection: func.isRequired,
  overlay: node,
  sectionIds: arrayOf(string),
  scrollPosition: number,
  status: number.isRequired,
};

PersonalPage.defaultProps = {
  isActive: false,
  overlay: null,
  sectionIds: [],
  scrollPosition: 0,
};

export const PersonalPageComponent = PersonalPage;
export default enhance(PersonalPage);
