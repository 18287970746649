import React from 'react';
import { bool } from 'prop-types';
import { compose } from '@blendle/recompose';
import altConnect from 'higher-order-components/altConnect';
import renderNothingIfIsHidden from 'higher-order-components/renderNothingIfIsHidden';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import { hasCapability } from 'selectors/capabilities';
import { FEATURE_CAPABILITY_BLENDLE_PREMIUM } from 'app-constants';
import PersonalPageStore from '../stores/PersonalPageStore';
import PersonalPageFooter from '../components/PersonalPageFooter';

const PersonalPageFooterContainer = ({ hasActivePremiumSubscription }) =>
  hasActivePremiumSubscription ? <PersonalPageFooter /> : null;

PersonalPageFooterContainer.propTypes = {
  hasActivePremiumSubscription: bool.isRequired,
};

export function mapStateToProps({ personalPageState: { hasEarlier }, featureCapabilitiesState }) {
  const hasActivePremiumSubscription = hasCapability(
    featureCapabilitiesState,
    FEATURE_CAPABILITY_BLENDLE_PREMIUM,
  );

  const isAllowedToSeeFooter = hasActivePremiumSubscription;

  // Also hide when there's no earlier page (eg. for new users)
  const isHidden = !hasEarlier || !isAllowedToSeeFooter;

  return {
    isHidden,
    hasActivePremiumSubscription,
  };
}

mapStateToProps.stores = { PersonalPageStore, FeatureCapabilitiesStore };

const enhance = compose(altConnect(mapStateToProps), renderNothingIfIsHidden);

export default enhance(PersonalPageFooterContainer);
