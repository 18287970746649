// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".m-sections-page .a-content{padding-top:0}.personalPage-ZbEve{font-family:\"GT-Walsheim\",sans-serif;flex:1;position:relative;overflow-x:hidden;background:#fff;-webkit-overflow-scrolling:auto}.hidden-us7NL{display:none}\n", ""]);
// Exports
exports.locals = {
	"personalPage": "personalPage-ZbEve",
	"hidden": "hidden-us7NL"
};
module.exports = exports;
